"use client";
import { Toaster } from "sonner";

import {
  CreateOrganizationDialog,
  CreateOrganizationStateProvider,
} from "@/components/dialogs/create-organization";
import {
  CreateProjectDialog,
  CreateProjectStateProvider,
} from "@/components/dialogs/create-project";
import {
  SourcePreviewDialog,
  SourcePreviewStateProvider,
} from "@/components/dialogs/source-preview";
import { createPolyfill } from "@/lib/polyfill";
import { runtimeEnv } from "@/lib/runtime";
import { useAuth } from "@/store/user/client";
import { SimpleLayoutSkeleton } from "@llamaindex/component/ui/loading";
import * as Sentry from "@sentry/nextjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ComposeContextProvider } from "foxact/compose-context-provider";
import type { ReactNode } from "react";
import { Suspense, use, useEffect, useState } from "react";
import { TooltipProvider } from "../ui/tooltip";

const polyfillPromise = createPolyfill();

async function enableMocking() {
  if (process.env.NODE_ENV !== "development") {
    return;
  }

  const { worker } = await import("@/mocks/browser");

  return worker.start();
}

declare global {
  // eslint-disable-next-line no-var
  var INIT_MSW: boolean;
}

export function ClientProviders({ children }: { children: ReactNode }) {
  use(polyfillPromise);
  useEffect(() => {
    if (globalThis.INIT_MSW) {
      return;
    }

    globalThis.INIT_MSW = true;
    if (runtimeEnv.NEXT_PUBLIC_ENABLE_MOCKS) {
      enableMocking();
    }
  }, []);
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          mutations: {
            onError: (error, variables, context) => {
              Sentry.captureException(error, {
                contexts: {
                  mutation: {
                    variables,
                    context,
                  },
                },
              });
            },
          },
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 60 * 1000,
          },
        },
      }),
  );

  const user = useAuth();

  // Tips: order is matter, make sure query client is on top or react query will not work because of missing context
  return (
    <TooltipProvider>
      <QueryClientProvider client={queryClient}>
        <ComposeContextProvider
          contexts={[
            <SourcePreviewStateProvider key="0" />,
            <CreateOrganizationStateProvider key="1" />,
            <CreateProjectStateProvider key="2" />,
          ]}
        >
          <Suspense fallback={<SimpleLayoutSkeleton />}>{children}</Suspense>
          <Toaster position="bottom-right" closeButton />
          <Suspense>
            <SourcePreviewDialog />
            {user && <CreateProjectDialog />}
            {user && <CreateOrganizationDialog />}
          </Suspense>
        </ComposeContextProvider>
      </QueryClientProvider>
    </TooltipProvider>
  );
}
