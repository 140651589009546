"use client";
import type { LoginProvider } from "@/lib/firebase/auth";
import type { Feature } from "@llamacloud/shared/feature";
import type { ReactNode } from "react";
import { createContext, use } from "react";

const AuthContext = createContext<Promise<User | null>>(Promise.resolve(null));

export const AuthProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: Promise<User | null>;
}) => {
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export interface Features {
  grant_parse_premium: boolean;
}

export interface User {
  email: string | undefined;
  uid: string | undefined;
  displayName: string | undefined;
  photoURL: string | undefined;
  allowed: Feature;
  parsePremium: boolean;
  parsingGrant: number;
  isStaff: boolean | false;
  features: Features;
  lastLoginProvider: LoginProvider | "oidc";
}

export const useAuth = (): User | null => {
  return use(use(AuthContext));
};
