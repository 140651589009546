"use client";
import { captureException } from "@sentry/nextjs";

import { useAppStore } from "@/store/client";
import { Button } from "@llamaindex/component/ui/button";
import { useCallback, useEffect } from "react";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    captureException(error);
  }, [error]);
  const message = error.message;
  const digest = error.digest;
  const onClickBackHome = useCallback(() => {
    useAppStore.persist.clearStorage();
    reset();
  }, [reset]);
  const onClickContact = useCallback(() => {
    // we might cannot use notion here because BYOC deployment does not have notion integration
    window.open("mailto:help@runllama.ai", "_blank");
  }, []);

  return (
    <html>
      <body className="flex size-full items-center justify-center">
        <div className="relative inline-flex flex-col items-center justify-center gap-4">
          <div className="text-5xl text-gray-900">Something went wrong.</div>
          <div className="text-[22px] font-bold text-black">{message}</div>
          <div className="max-w-[363px] text-center text-base font-normal leading-normal text-gray-900">
            Please try again later or contact support with the digest id{" "}
            {digest}
          </div>
          <div className="inline-flex items-start justify-center gap-[7px]">
            <Button
              variant="outline"
              className="text-sm font-semibold text-gray-900"
              onClick={onClickContact}
            >
              Contact Support
            </Button>
            <Button
              className="text-sm font-semibold text-white"
              onClick={onClickBackHome}
            >
              Reset
            </Button>
          </div>
        </div>
      </body>
    </html>
  );
}
