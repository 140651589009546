import { useNewOrganization } from "@/hooks/organization";
import { Button } from "@llamaindex/component/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@llamaindex/component/ui/dialog";
import { Input } from "@llamaindex/component/ui/input";
import { toast } from "@llamaindex/component/ui/use-toast";
import { createContextState } from "foxact/context-state";

type CreateOrganizationState =
  | {
      open: true;
    }
  | {
      open: false;
    };
export const [
  CreateOrganizationStateProvider,
  useCreateOrganizationState,
  useSetCreateOrganizationState,
] = createContextState<CreateOrganizationState>({
  open: false,
});

export const CreateOrganizationDialog = () => {
  const state = useCreateOrganizationState();
  const setState = useSetCreateOrganizationState();
  const { mutateAsync } = useNewOrganization();
  return (
    <Dialog open={state.open} onOpenChange={(open) => setState({ open })}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create Organization</DialogTitle>
          <DialogDescription className="sr-only">
            Organizations are a way to group projects together. They can be used
            to manage access control and billing.
          </DialogDescription>
        </DialogHeader>
        <form
          className="contents"
          action={async (form) => {
            const organizationName = form.get("organization-name");
            if (typeof organizationName === "string" && organizationName) {
              toast.promise(
                mutateAsync({
                  name: organizationName,
                }),
                {
                  loading: "Creating organization...",
                  success: "Organization created",
                  error: "Organization could not be created",
                },
              );
            }
          }}
        >
          <Input name="organization-name" placeholder="Organization Name" />
          <DialogFooter>
            <DialogClose asChild>
              <Button type="submit">Create</Button>
            </DialogClose>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
