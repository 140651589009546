"use client";
import { SignIn } from "@/components/section/login";
import { LoginLayout } from "@/layouts/login-layout";
import { googleAuth } from "@/lib/firebase/client";
import { assertExists } from "@llamacloud/shared/utils";

// follow React.lazy
// eslint-disable-next-line no-restricted-syntax
export default function GoogleLoginPage() {
  assertExists(googleAuth, "googleAuth");

  return (
    <LoginLayout>
      <SignIn />
    </LoginLayout>
  );
}
