import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.0-canary.17_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_rea_6b466pvgdhuy5tdczav5b2uyn4/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.0-canary.17_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_rea_6b466pvgdhuy5tdczav5b2uyn4/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.0-canary.17_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_rea_6b466pvgdhuy5tdczav5b2uyn4/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.0-canary.17_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_rea_6b466pvgdhuy5tdczav5b2uyn4/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.0-canary.17_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_rea_6b466pvgdhuy5tdczav5b2uyn4/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.0-canary.17_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_rea_6b466pvgdhuy5tdczav5b2uyn4/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.0-canary.17_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_rea_6b466pvgdhuy5tdczav5b2uyn4/node_modules/next/dist/lib/metadata/async-metadata.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.0-canary.17_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_rea_6b466pvgdhuy5tdczav5b2uyn4/node_modules/next/dist/lib/metadata/metadata-boundary.js");
