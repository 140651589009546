"use client";
import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@llamaindex/component/ui/sidebar";
import { ChartNoAxesColumnIncreasing, Mail } from "lucide-react";
import Link from "next/link";
import React from "react";

export const ContactSection: React.FC = () => {
  return (
    <SidebarGroup className="group-data-[collapsible=icon]:hidden">
      <SidebarGroupLabel>Contacts</SidebarGroupLabel>
      <SidebarGroupContent>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton size="sm" asChild>
              <a
                href="mailto:support@runllama.ai"
                className="flex cursor-pointer items-center rounded py-1"
              >
                <Mail className="size-4" />
                <span>Email Us</span>
              </a>
            </SidebarMenuButton>
          </SidebarMenuItem>
          <SidebarMenuItem>
            <SidebarMenuButton size="sm" asChild>
              <Link
                href="https://llamaindex.statuspage.io"
                className="flex items-center rounded py-1"
              >
                <ChartNoAxesColumnIncreasing className="size-4" />
                <span>Status</span>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarGroupContent>
    </SidebarGroup>
  );
};
