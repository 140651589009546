"use client";
import { deleteSession } from "@/actions/session";
import { Button } from "@llamaindex/component/ui/button";
import { ShieldAlert } from "lucide-react";
import Link from "next/link";

export default function NotAuthorizedPage() {
  return (
    <div className="flex h-[calc(100vh-8rem)] w-full flex-col items-center justify-center gap-6">
      <div className="flex flex-col items-center gap-2 text-center">
        <div className="rounded-full bg-muted p-4">
          <ShieldAlert className="size-8 text-muted-foreground" />
        </div>
        <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl">
          Not Authorized
        </h1>
        <p className="max-w-[600px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
          You don&apos;t have permission to access this page. Please sign in
          with the appropriate credentials.
        </p>
      </div>
      <div className="flex gap-4">
        <Button asChild>
          <Link
            href="/login"
            onClick={() => {
              deleteSession().catch(console.error);
            }}
          >
            Clean up session and sign in
          </Link>
        </Button>
      </div>
    </div>
  );
}
