"use client";
import { revalidateSession } from "@/actions/session";
import { handleRedirectResult } from "@/lib/firebase/auth";
import {
  googleAuth,
  redirectResultPromise,
  resolvedPromise,
  resolvedPromiseFn,
} from "@/lib/firebase/client";
import { runtimeFlags } from "@/lib/runtime";
import { assertExists } from "@llamacloud/shared/utils";
import { useQueryClient } from "@tanstack/react-query";
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { useRouter, useSearchParams } from "next/navigation";
import { type ReactNode, use, useEffect } from "react";
import { toast } from "sonner";

function FirebaseLayout({ children }: { children: ReactNode }) {
  const redirectResult = use(redirectResultPromise);
  const { replace } = useRouter();
  const queryClient = useQueryClient();
  const searchParams = useSearchParams();
  const email = searchParams.get("email");

  useEffect(() => {
    assertExists(googleAuth, "googleAuth");
    if (isSignInWithEmailLink(googleAuth, window.location.href)) {
      if (email) {
        toast.promise(
          signInWithEmailLink(
            googleAuth,
            decodeURIComponent(email),
            window.location.href,
          )
            .then((result) => {
              handleRedirectResult(result).then((shouldRedirect: boolean) => {
                if (shouldRedirect) {
                  Promise.all([
                    queryClient.invalidateQueries(),
                    revalidateSession(),
                  ]).then(() => {
                    replace("/");
                  });
                  return;
                }
              });
            })
            .then(),
          {
            loading: "Logging in...",
            success: "Logged in",
            error: `Error logging in with email ${email}`,
          },
        );
      } else {
        toast.error("No email found in link");
      }
    }
  }, [email, queryClient, replace]);

  useEffect(() => {
    assertExists(googleAuth, "googleAuth");
    if (redirectResult && !resolvedPromise) {
      resolvedPromiseFn();
      toast.promise(
        handleRedirectResult(redirectResult).then((shouldRedirect: boolean) => {
          if (shouldRedirect) {
            Promise.all([
              queryClient.invalidateQueries(),
              revalidateSession(),
            ]).then(() => {
              replace("/");
            });
            return;
          }
        }),
        {
          loading: "Logging in...",
          success: "Logged in",
          error: "Error logging in",
        },
      );
    }
  }, [queryClient, redirectResult, replace]);

  return children;
}

function Layout({ children }: { children: ReactNode }) {
  return children;
}

export default runtimeFlags.authMode === "google" ? FirebaseLayout : Layout;
