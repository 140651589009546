"use client";
import {
  ColoredBarChart,
  ColoredDock,
  ColoredFileOutput,
  ColoredFolderTree,
  ColoredGrid,
  ColoredSettings,
} from "@/components/icons";
import { DynamicDocumentLink } from "@/components/section/dynamic-document-link";
import { useIsInternalUser } from "@/hooks/is-internal-user";
import { LoginProvider } from "@/lib/firebase/auth";
import { runtimeFlags } from "@/lib/runtime";
import {
  useCurrentOrganizationId,
  useCurrentProjectId,
} from "@/store/current-project-id/client";
import { useAuth } from "@/store/user/client";
import { Icons } from "@llamaindex/component/ui/icons";
import { Label } from "@llamaindex/component/ui/label";
import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@llamaindex/component/ui/sidebar";
import {
  BarChartBig,
  Dock,
  FileOutput,
  FolderTree,
  Grid,
  House,
  Key,
  ListChecks,
  Settings,
} from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useMemo } from "react";

export const NAV_ITEM_TOOLTIP_DIRECTION = "right";

interface NavItemProps {
  href: string;
  icon: React.ReactNode;
  activeIcon: React.ReactNode;
  label: React.ReactNode;
  isActive: boolean;
  tooltip: string;
}

const NavItem: React.FC<NavItemProps> = ({
  href,
  icon,
  activeIcon,
  label,
  isActive,
  tooltip,
}) => {
  return (
    <SidebarMenuItem>
      <SidebarMenuButton asChild tooltip={tooltip}>
        <Link href={href}>
          {isActive ? activeIcon : icon}
          <Label className="cursor-pointer group-data-[collapsible=icon]:hidden">
            {label}
          </Label>
        </Link>
      </SidebarMenuButton>
    </SidebarMenuItem>
  );
};

export const MainSection: React.FC = () => {
  const user = useAuth();
  const isInternalUser = useIsInternalUser();

  const currentProjectId = useCurrentProjectId();
  const currentOrganizationId = useCurrentOrganizationId();

  const pathname = usePathname();

  const arizeUrl = useMemo(() => {
    switch (user?.lastLoginProvider) {
      case LoginProvider.GITHUB:
        return "https://llamatrace.com/auth/github";
      default:
        return "https://llamatrace.com/auth/google";
    }
  }, [user?.lastLoginProvider]);

  return (
    <>
      <SidebarGroup>
        <SidebarGroupLabel>Overview</SidebarGroupLabel>
        <SidebarGroupContent>
          <SidebarMenu>
            <NavItem
              href={`/project/${currentProjectId}`}
              icon={<House className="inline-block size-4" />}
              activeIcon={
                <House className="inline-block size-4 text-blue-500" />
              }
              label="Home"
              tooltip="Home"
              isActive={pathname === `/project/${currentProjectId}`}
            />
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>
      <SidebarGroup>
        <SidebarGroupLabel>Tools</SidebarGroupLabel>
        <SidebarGroupContent>
          <SidebarMenu>
            <NavItem
              href={`/project/${currentProjectId}/parse`}
              icon={<Grid className="inline-block size-4" />}
              activeIcon={<ColoredGrid className="inline-block size-4" />}
              label="Parse"
              tooltip="Parse"
              isActive={pathname.endsWith("/parse")}
            />
            {user?.allowed.index && currentProjectId && (
              <NavItem
                href={`/project/${currentProjectId}/pipeline`}
                icon={<FolderTree className="inline-block size-4" />}
                activeIcon={
                  <ColoredFolderTree className="inline-block size-4" />
                }
                label="Index"
                tooltip="Index"
                isActive={pathname === `/project/${currentProjectId}/pipeline`}
              />
            )}
            {user?.allowed.app && currentProjectId && (
              <NavItem
                href={`/project/${currentProjectId}/app`}
                icon={<Dock className="inline-block size-4" />}
                activeIcon={<ColoredDock className="inline-block size-4" />}
                label={
                  <>
                    <span>Apps</span>
                    <span className="ml-2 rounded bg-[#e1f6fd] px-[9px] py-[3px] text-xs font-medium text-[#00749e]">
                      Beta
                    </span>
                  </>
                }
                tooltip="Apps"
                isActive={pathname === `/project/${currentProjectId}/app`}
              />
            )}
            {user?.allowed.eval && currentProjectId && (
              <NavItem
                href={`/project/${currentProjectId}/evals`}
                icon={<BarChartBig className="inline-block size-4" />}
                activeIcon={<ColoredBarChart className="inline-block size-4" />}
                label="Evals"
                tooltip="Evals"
                isActive={pathname === `/project/${currentProjectId}/evals`}
              />
            )}
            {user?.allowed.report && currentProjectId && (
              <NavItem
                href={`/project/${currentProjectId}/report`}
                icon={<ListChecks className="inline-block size-4" />}
                activeIcon={<ListChecks className="inline-block size-4" />}
                label={
                  <>
                    <span>Report</span>
                    <span className="ml-2 rounded bg-[#e1f6fd] px-[9px] py-[3px] text-xs font-medium text-[#00749e]">
                      Beta
                    </span>
                  </>
                }
                tooltip="Report"
                isActive={pathname === `/project/${currentProjectId}/report`}
              />
            )}
            {user?.allowed.extraction &&
              currentProjectId &&
              runtimeFlags.authMode !== "self-host-byoc" && (
                <NavItem
                  href={`/project/${currentProjectId}/extraction`}
                  icon={<FileOutput className="inline-block size-4" />}
                  activeIcon={
                    <ColoredFileOutput className="inline-block size-4" />
                  }
                  label={
                    <>
                      <span>Extraction</span>
                      <span className="ml-2 rounded bg-[#e1f6fd] px-[9px] py-[3px] text-xs font-medium text-[#00749e]">
                        Beta
                      </span>
                    </>
                  }
                  tooltip="Extraction"
                  isActive={
                    pathname === `/project/${currentProjectId}/extraction`
                  }
                />
              )}
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>
      <SidebarGroup>
        <SidebarGroupLabel>Resources</SidebarGroupLabel>
        <SidebarGroupContent>
          <SidebarMenu>
            <NavItem
              href={`/organization/${currentOrganizationId}/settings/organization`}
              icon={<Settings className="inline-block size-4" />}
              activeIcon={<ColoredSettings className="inline-block size-4" />}
              label="Settings"
              tooltip="Settings"
              isActive={
                pathname.startsWith(`/organization`) &&
                pathname.includes("settings")
              }
            />
            <NavItem
              href={`/project/${currentProjectId}/api-key`}
              icon={<Key className="inline-block size-4" />}
              activeIcon={<Key className="inline-block size-4" />}
              label="API Key"
              tooltip="API Key"
              isActive={pathname === "/api-key"}
            />
            <DynamicDocumentLink />
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>
      {runtimeFlags.authMode !== "self-host-byoc" && (
        <SidebarGroup>
          <SidebarGroupLabel>Partner Integrations</SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              <NavItem
                href={arizeUrl}
                icon={<Icons.arize className="inline-block size-4" />}
                activeIcon={<Icons.arize className="inline-block size-4" />}
                label="LlamaTrace with Arize"
                tooltip="LlamaTrace with Arize"
                isActive={false}
              />
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      )}
    </>
  );
};
