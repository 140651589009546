import { markdownToHTML } from "@/lib/markdown";
import { Eye } from "lucide-react";
import { useMemo } from "react";
import { Button } from "./button";

interface NodeViewProps {
  idx: number;
  id: string;
  text: string;
  metadata: Record<string, unknown>;
  onViewInFile?: (nodeId: string) => void;
}

const NodeView = ({ idx, id, text, metadata, onViewInFile }: NodeViewProps) => {
  const html = useMemo(() => markdownToHTML(text), [text]);
  return (
    <div
      id={`node-${id}`}
      className="grid grid-cols-[94px_auto] gap-x-2 gap-y-4 rounded-md border border-slate-200 bg-white p-4 text-sm font-normal leading-6"
    >
      <div className="col-span-2 flex flex-row content-center gap-x-4">
        <div className="my-auto min-w-[85px] text-base font-medium">
          Node {idx}
        </div>
        <div>
          {onViewInFile ? (
            <Button variant="outline" onClick={() => onViewInFile(id)}>
              <Eye />
              &nbsp;&nbsp;View in File
            </Button>
          ) : null}
        </div>
      </div>
      <div>Text:</div>
      <div
        className="overflow-scroll rounded-md border border-slate-200 px-4 py-2"
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
      <div>Metadata:</div>
      <div>
        {Object.entries(metadata).map(([key, value]) => {
          return (
            <div key={key}>
              {key}: {String(value)}
            </div>
          );
        })}
      </div>
      <div>ID:</div>
      <div>{id}</div>
      {/* TODO: add back when it's more useful */}
      {/* <div>Relationships:</div>
      <div>
        {Object.entries(relationships).map(([key, value]) => {
          return (
            <div key={key}>
              {key}: {Array.isArray(value) ? "Multiple" : value.nodeId}
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export { NodeView };
