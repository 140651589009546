import { useCurrentProjectId } from "@/store/current-project-id/client";
import type {
  PageScreenshotNodeWithScore,
  TextNode,
} from "@llamaindex/cloud/api";
import { Button } from "@llamaindex/component/ui/button";
import { NodeView } from "@llamaindex/component/ui/node-view";
import { ScreenshotView } from "@llamaindex/component/ui/screenshot-view";
import React, { useEffect, useState } from "react";

interface NodeListProps {
  nodes: TextNode[];
  imageNodes?: PageScreenshotNodeWithScore[];
  maxNodesToShow?: number;
  onViewInFile?: (nodeId: string) => void;
  selectedNodeId?: string;
}

export const NodeList: React.FC<NodeListProps> = ({
  nodes,
  imageNodes,
  maxNodesToShow,
  onViewInFile,
  selectedNodeId,
}) => {
  const [showAllNodes, setShowAllNodes] = useState(false);
  const currentProjectId = useCurrentProjectId();

  const allNodes: (TextNode | PageScreenshotNodeWithScore)[] = [
    ...nodes,
    ...(imageNodes ?? []),
  ];

  const visibleNodes =
    showAllNodes || !maxNodesToShow
      ? allNodes
      : allNodes.slice(0, maxNodesToShow);

  const visibleTextNodes = visibleNodes.slice(0, nodes.length) as TextNode[];
  const visibleImageNodes = visibleNodes.slice(
    nodes.length,
  ) as PageScreenshotNodeWithScore[];

  const handleShowAllNodes = () => {
    setShowAllNodes(true);
  };

  useEffect(() => {
    if (selectedNodeId) {
      const selectedNodeIdx = visibleTextNodes.findIndex(
        (node) => node.id_ === selectedNodeId,
      );
      if (selectedNodeIdx !== -1) {
        const selectedNode = visibleTextNodes[selectedNodeIdx];
        const selectedNodesEl = document.querySelectorAll(
          `#node-${selectedNode?.id_}`,
        );

        for (const el of selectedNodesEl) {
          el.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    }
  }, [selectedNodeId, visibleTextNodes]);

  if (nodes.length === 0) {
    return <div className="text-center">No nodes to show</div>;
  }

  return (
    <div className="flex-col space-y-6">
      {visibleTextNodes.map((node, idx) => {
        return (
          <NodeView
            key={node.id_}
            idx={idx + 1}
            id={node.id_ ?? ""}
            text={node.text ?? ""}
            metadata={node.extra_info ?? {}}
            onViewInFile={onViewInFile}
          />
        );
      })}
      {visibleImageNodes.map((node) => {
        const id = `${node.node.file_id}-${node.node.page_index}`;
        return (
          <ScreenshotView
            id={id}
            key={id}
            idx={visibleNodes.indexOf(node) + 1}
            fileId={node.node.file_id}
            pageIndex={node.node.page_index}
            projectId={currentProjectId}
          />
        );
      })}
      {!showAllNodes && maxNodesToShow && nodes.length > maxNodesToShow && (
        <div className="flex justify-center">
          <Button onClick={handleShowAllNodes}>
            Show All Nodes ({nodes.length - maxNodesToShow} more)
          </Button>
        </div>
      )}
    </div>
  );
};
