"use client";
import { MainSection } from "@/layouts/sidebar-layout/navigation/main-section";
import { ProjectSwitcher } from "@/layouts/sidebar-layout/project-switcher";
import { useBaseLayout } from "@/layouts/sidebar-layout/shared";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@llamaindex/component/ui/alert";
import { Button } from "@llamaindex/component/ui/button";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuSkeleton,
  SidebarSeparator,
  SidebarTrigger,
} from "@llamaindex/component/ui/sidebar";
import { AlertCircle } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { Suspense } from "react";
import { ErrorBoundary, type FallbackProps } from "react-error-boundary";

const NavSkeleton = () => (
  <SidebarMenu>
    {Array.from({ length: 5 }).map((_, index) => (
      <SidebarMenuItem key={index}>
        <SidebarMenuSkeleton />
      </SidebarMenuItem>
    ))}
  </SidebarMenu>
);

const NavError = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <div className="flex size-full flex-col items-center justify-center p-4">
      <Alert variant="destructive" className="w-full">
        <AlertCircle className="size-4" />
        <AlertTitle>Navigation Error</AlertTitle>
        <AlertDescription className="mt-2 flex flex-col gap-2">
          <p className="text-sm">Failed to load navigation items.</p>
          {process.env.NODE_ENV === "development" && (
            <p className="text-xs opacity-80">{error.message}</p>
          )}
          <Button
            onClick={resetErrorBoundary}
            variant="outline"
            size="sm"
            className="mt-2"
          >
            Try again
          </Button>
        </AlertDescription>
      </Alert>
    </div>
  );
};

export const AppSidebar = () => {
  const { serverNavigationItems } = useBaseLayout();
  return (
    <Sidebar collapsible="icon">
      <ErrorBoundary fallbackRender={NavError}>
        <Suspense>
          <SidebarTrigger />
        </Suspense>
        <SidebarHeader>
          <Suspense fallback={<NavSkeleton />}>
            <Link href="/" className="p-4 group-data-[collapsible=icon]:p-0">
              <Image
                src="/assets/logo.svg"
                alt="LlamaIndex"
                className="h-8 w-auto cursor-pointer group-data-[collapsible=icon]:hidden"
                height={64}
                width={120}
              />
              <Image
                src="/assets/rounded_corners_logo.png"
                alt="LlamaIndex"
                className="hidden h-8 w-auto cursor-pointer group-data-[collapsible=icon]:block"
                height={32}
                width={32}
              />
            </Link>
            <SidebarSeparator />
            <ProjectSwitcher />
            <SidebarSeparator />
          </Suspense>
        </SidebarHeader>
      </ErrorBoundary>
      <SidebarContent className="text-sm">
        <ErrorBoundary fallbackRender={NavError}>
          <Suspense fallback={<NavSkeleton />}>
            <MainSection />
          </Suspense>
        </ErrorBoundary>
      </SidebarContent>
      <SidebarFooter className="p-0">
        <ErrorBoundary fallbackRender={NavError}>
          <Suspense
            fallback={
              <SidebarMenu>
                {Array.from({ length: 5 }).map((_, index) => (
                  <SidebarMenuItem key={index}>
                    <SidebarMenuSkeleton showIcon />
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            }
          >
            {serverNavigationItems}
          </Suspense>
        </ErrorBoundary>
      </SidebarFooter>
    </Sidebar>
  );
};
