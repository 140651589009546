"use client";
import { Label } from "@llamaindex/component/ui/label";
import {
  SidebarMenuButton,
  SidebarMenuItem,
} from "@llamaindex/component/ui/sidebar";
import { Book } from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useMemo } from "react";

export const DynamicDocumentLink = () => {
  const pathname = usePathname();
  const href = useMemo(() => {
    if (pathname.includes("/parse")) {
      return "https://docs.cloud.llamaindex.ai/category/API/parsing";
    }
    return "https://docs.cloud.llamaindex.ai";
  }, [pathname]);
  return (
    <SidebarMenuItem>
      <SidebarMenuButton tooltip="Documentation">
        <Link href={href}>
          <Book className="inline-block size-4" />
          <Label className="ml-2 group-data-[collapsible=icon]:hidden">
            Documentation
          </Label>
        </Link>
      </SidebarMenuButton>
    </SidebarMenuItem>
  );
};
