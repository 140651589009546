"use client";
import type {
  IntervalUsageAndPlan,
  ParsingUsage,
  Permission,
} from "@llamaindex/cloud/api";
import React, { createContext, use } from "react";

const ServerResourceContext = createContext<{
  permission: Promise<Pick<Permission, "name" | "access">[]>;
  usageAndPlan: Promise<IntervalUsageAndPlan>;
  parsingUsage: Promise<ParsingUsage>;
}>({
  usageAndPlan: Promise.resolve({
    start_window: null,
    end_window: null,
    plan: null,
    usage: null,
  }),
  permission: Promise.resolve([]),
  parsingUsage: Promise.resolve({
    usage_pdf_pages: 0,
    max_pdf_pages: null,
  }),
});

export const CloudPermissions = {
  FULL_WRITE: "full_write",
  READ_ONLY: "read_only",
} as const;

export type UserPermissionProviderProps = {
  value: {
    permission: Promise<Pick<Permission, "name" | "access">[]>;
    usageAndPlan: Promise<IntervalUsageAndPlan>;
    parsingUsage: Promise<ParsingUsage>;
  };
  children: React.ReactNode;
};

export const ServerResourceProvider: React.FC<UserPermissionProviderProps> = (
  props,
) => {
  return (
    <ServerResourceContext.Provider value={props.value}>
      {props.children}
    </ServerResourceContext.Provider>
  );
};

/**
 * @internal
 */
const useResource = () => React.useContext(ServerResourceContext);

export function useUsage() {
  const resource = useResource();
  return use(resource.parsingUsage);
}

export function usePermission(
  permission: (typeof CloudPermissions)[keyof typeof CloudPermissions],
): boolean {
  const resource = useResource();

  if (resource === null) {
    console.warn("PermissionProvider not found in the component tree");
    return false;
  }

  return (
    use(resource.permission).find(({ name }) => name === permission)?.access ===
    true
  );
}

export const useMemberLimit = (): number => {
  const resource = useResource();

  if (resource === null) {
    console.warn("PermissionProvider not found in the component tree");
    return 0;
  }

  const { usage, plan } = use(resource.usageAndPlan);

  if (usage && plan) return (usage.total_users ?? 0) - (plan.total_users ?? 0);
  return 1; // should only happen in pricingv2 is disabled
};

export const useReachIndexLimit = (): number => {
  const resource = useResource();

  if (resource === null) {
    console.warn("PermissionProvider not found in the component tree");
    return 0;
  }

  const { usage, plan } = use(resource.usageAndPlan);
  if (usage && plan) {
    return (usage.total_indexes ?? 0) - (plan.total_indexes ?? 0);
  }
  return 1; // should only happen in pricingv2 is disabled
};

export const useReachIndexedPageLimit = (): number => {
  const resource = useResource();

  if (resource === null) {
    console.warn("PermissionProvider not found in the component tree");
    return 0;
  }

  const { usage, plan } = use(resource.usageAndPlan);
  if (usage && plan) {
    return (usage.total_indexed_pages ?? 0) - (plan.total_indexed_pages ?? 0);
  }
  return 1; // should only happen in pricingv2 is disabled
};

export const useCanCreateExternalIndex = (): boolean => {
  const resource = useResource();
  if (resource === null) {
    console.warn("PermissionProvider not found in the component tree");
    return true;
  }

  const { usage, plan } = use(resource.usageAndPlan);
  if (usage && plan) {
    return plan.allowed_external_index ?? false;
  }
  return true; // should only happen in pricingv2 is disabled
};
