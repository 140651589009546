import { useNewProject } from "@/hooks/project";
import { useCurrentOrganizationId } from "@/store/current-project-id/client";
import { Button } from "@llamaindex/component/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@llamaindex/component/ui/dialog";
import { Input } from "@llamaindex/component/ui/input";
import { toast } from "@llamaindex/component/ui/use-toast";
import { createContextState } from "foxact/context-state";

type CreateProjectState =
  | {
      open: true;
    }
  | {
      open: false;
    };

export const [
  CreateProjectStateProvider,
  useCreateProjectState,
  useSetCreateProjectState,
] = createContextState<CreateProjectState>({
  open: false,
});

export const CreateProjectDialog = () => {
  const state = useCreateProjectState();
  const setState = useSetCreateProjectState();
  const currentOrganizationId = useCurrentOrganizationId();
  const { mutateAsync } = useNewProject();

  return (
    <Dialog open={state.open} onOpenChange={(open) => setState({ open })}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create Project</DialogTitle>
          <DialogDescription className="sr-only">
            Projects are a way to organize your code and data. They can be used
            to manage access control and billing.
          </DialogDescription>
        </DialogHeader>
        <form
          className="contents"
          action={async (form) => {
            const projectName = form.get("project-name");
            if (typeof projectName === "string" && projectName) {
              toast.promise(
                mutateAsync({
                  name: projectName,
                  organizationId: currentOrganizationId,
                }),
                {
                  loading: "Creating project...",
                  success: "Project created",
                  error: "Project could not be created",
                },
              );
            }
          }}
        >
          <Input name="project-name" placeholder="Project Name" />
          <DialogFooter>
            <DialogClose asChild>
              <Button type="submit">Create</Button>
            </DialogClose>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
