import { NodeList } from "@/components/section/node-list";
import type { TextNode } from "@llamaindex/cloud";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@llamaindex/component/ui/dialog";
import { createContextState } from "foxact/context-state";
import { useCallback } from "react";

type SourcePreviewState =
  | {
      open: true;
      sources: TextNode[];
    }
  | {
      open: false;
      sources: TextNode[] | null;
    };

const [
  SourcePreviewStateProvider,
  useSourcePreviewState,
  useSetSourcePreviewState,
] = createContextState<SourcePreviewState>({
  open: false,
  sources: null,
});

export {
  SourcePreviewStateProvider,
  useSetSourcePreviewState,
  useSourcePreviewState,
};

export function SourcePreviewDialog() {
  const setState = useSetSourcePreviewState();
  const state = useSourcePreviewState();
  return (
    <Dialog
      open={state.open}
      onOpenChange={useCallback(
        (open: boolean) => {
          if (!open) {
            setState((state) => ({ ...state, open: false }));
          }
        },
        [setState],
      )}
    >
      <DialogContent className="max-h-[95vh] max-w-[50vw] overflow-scroll">
        <div className="flex flex-col overflow-auto">
          <DialogHeader>
            <DialogTitle className="p-2">Source Nodes</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col items-center justify-start overflow-auto bg-slate-50 py-4">
            {state.sources ? (
              <NodeList nodes={state.sources} />
            ) : (
              "No source to preview"
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
