import type { ReactNode } from "react";

export function SimpleLayout({ children }: { children: ReactNode }) {
  return (
    <main
      className="relative flex h-screen max-h-screen min-h-screen w-full flex-col overflow-hidden"
      data-testid="root-layout"
    >
      {children}
    </main>
  );
}
