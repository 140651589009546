"use client";
import { useOrganization } from "@/hooks/organization";
import { initializeCrisp } from "@/lib/crisp";
import { useCurrentOrganizationId } from "@/store/current-project-id/client";
import type { User } from "@/store/user/client";
import { useAuth } from "@/store/user/client";
import { ParsePlanLevel } from "node_modules/@llamaindex/cloud/src/api/types.gen";
import { useEffect } from "react";

const UserOrganizationEffect = ({ user }: { user: User }) => {
  const currentOrganizationId = useCurrentOrganizationId();
  const { data: currentOrganization } = useOrganization(currentOrganizationId);

  useEffect(() => {
    try {
      if (
        currentOrganization?.parse_plan_level === ParsePlanLevel.PREMIUM ||
        user.allowed.index
      ) {
        initializeCrisp(user);
      }
    } catch (error) {
      console.error("Error Initializing Crisp", error);
    }

    import("@datadog/browser-rum")
      .then(({ datadogRum }) => {
        datadogRum.setUser({
          id: user.uid,
          email: user.email,
          name: user.displayName,
        });
      })
      .catch(console.error);
  }, [user, currentOrganization]);

  return null;
};

export const UserSideEffect = () => {
  const user = useAuth();

  if (!user) {
    return null;
  }

  return <UserOrganizationEffect user={user} />;
};
